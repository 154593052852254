.planner-page-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3000;
  background-color: #ffffff;
}
.planner-page-container .side-container {
  top: 0;
  height: 100vh;
}
.planner-page-container .side-container .side-container-body {
  max-height: 100vh;
}